"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _LeaveReview = _interopRequireDefault(require("./LeaveReview"));
var _Page = require("./Page");
var _ReadReview = _interopRequireDefault(require("./ReadReview"));
const _excluded = ["data"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const query = exports.query = (0, _client.gql)`
    fragment ReviewModal on OrderItem {
        ... on Booking {
            id
            review {
                ...Review
            }
            rentalUnit {
                id
                flexCancellationDays
                reviewStats {
                    ...ReviewStats
                }
                accommodation {
                    id
                    ownerName
                    jpegOwner: ownerImage {
                        transform(config: AVATAR, format: JPEG) {
                            ...Transform
                        }
                    }
                    webpOwner: ownerImage {
                        transform(config: AVATAR, format: WEBP) {
                            ...Transform
                        }
                    }
                }
            }
        }
        ...OrderSummaryItem
    }

    mutation LeaveReview($input: LeaveReviewInput!) {
        leaveReview(input: $input) {
            review {
                ...Review
            }
        }
    }

    fragment Review on Review {
        id
        average
        comment
        date
        ratingHospitality
        ratingLayout
        ratingLocation
        ratingMaintenance
        ratingValueForMoney
        tips
        title
        travelGroupType
        reply
    }
`;
const ReviewModal = _ref => {
  let {
      data
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  // used to see if we just left a review so we can show the "thank you for you review" part
  const {
    0: leftReview,
    1: setLeftReview
  } = (0, _react.useState)(data.review ? null : false);
  return __jsx(StyledModal, props, __jsx(ModalWrapper, null, __jsx(Content, null, typeof leftReview === 'boolean' && __jsx(_LeaveReview.default, {
    booking: data,
    leftReview: leftReview,
    setLeftReview: setLeftReview
  }), typeof leftReview !== 'boolean' && __jsx(_ReadReview.default, {
    data: data
  }))));
};
const StyledModal = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "ReviewModal__StyledModal",
  componentId: "vewvcb-0"
})(["", " [data-reach-dialog-content]{padding:0;display:flex;justify-content:center;}"], _Page.wrapperPadding);
const ModalWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "ReviewModal__ModalWrapper",
  componentId: "vewvcb-1"
})(["flex-direction:column;display:flex;min-height:90vh;width:100%;"]);
const Content = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ReviewModal__Content",
  componentId: "vewvcb-2"
})(["width:100%;padding:", " 0;@media screen and (min-width:", "){padding:", " 0;}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['80_XXLarge']);
var _default = exports.default = ReviewModal;