"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _Review = _interopRequireDefault(require("./Review"));
var _ReviewStats = _interopRequireDefault(require("./ReviewStats"));
var _SummaryBar = _interopRequireDefault(require("./SummaryBar"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const ReadReview = ({
  data
}) => {
  const {
    rentalUnit,
    review
  } = data;
  if (!review || !rentalUnit) {
    return null;
  }
  const {
    ownerName,
    jpegOwner,
    webpOwner
  } = rentalUnit.accommodation;
  const {
    average,
    ratingHospitality,
    ratingLocation,
    ratingLayout,
    ratingMaintenance,
    ratingValueForMoney
  } = review;
  return __jsx(_grid.Flex, {
    flexDirection: ['column-reverse', null, null, 'row'],
    justifyContent: "space-between",
    width: 1
  }, __jsx(_grid.Box, {
    flex: 1,
    mr: [null, null, 5, 8]
  }, __jsx(_grid.Box, null, __jsx(_ReviewStats.default, {
    variant: "light",
    reviewStats: {
      average,
      hospitality: ratingHospitality,
      layout: ratingLayout,
      location: ratingLocation,
      maintenance: ratingMaintenance,
      valueForMoney: ratingValueForMoney
    }
  }), __jsx(StyledReview, {
    hideRating: true,
    review: review,
    ownerName: ownerName,
    ownerImage: jpegOwner && webpOwner && _objectSpread(_objectSpread({}, (0, _createImgProps.default)(jpegOwner, webpOwner)), {}, {
      loading: 'lazy'
    })
  }))), __jsx(_grid.Box, {
    mb: 6,
    ml: [3, null, 4]
  }, __jsx(_SummaryBar.default, {
    data: data,
    showOnMobile: true,
    hidePrices: true
  }), __jsx(_Divider.default, {
    className: "lt-m",
    m: 0,
    mt: 5
  })));
};
var _default = exports.default = ReadReview;
const StyledReview = (0, _styledComponents.default)(_Review.default).withConfig({
  displayName: "ReadReview__StyledReview",
  componentId: "sc-1yaktbi-0"
})(["@media screen and (min-width:", "){margin-top:", ";}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.spacing['50_Semi']);