"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("./auth");
var _fileSaver = require("file-saver");
var _getStorageWithExpiry = _interopRequireDefault(require("./getStorageWithExpiry"));
const downloadTorFile = async (url, fileName) => {
  const token = (0, _getStorageWithExpiry.default)('local')?.getItem(_auth.ACCESS_TOKEN);
  const result = await fetch(url, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  if (result.ok) {
    const blob = await result.blob();
    (0, _fileSaver.saveAs)(blob, fileName ?? undefined);
  }
};
var _default = exports.default = downloadTorFile;