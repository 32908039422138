"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderItemIsBooking = exports.fragment = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _link = _interopRequireDefault(require("next/link"));
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _TenantContext = require("../context/TenantContext");
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _createUnitTitle = _interopRequireDefault(require("../utils/createUnitTitle"));
var _downloadTORFile = _interopRequireDefault(require("../utils/downloadTORFile"));
var _order = require("../utils/order");
var _trip = require("../utils/trip");
var _Anchor = _interopRequireDefault(require("./designsystem/Anchor"));
var _OrderItem = _interopRequireWildcard(require("./designsystem/OrderItem"));
var _ReviewModal = _interopRequireDefault(require("./ReviewModal"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _Download = _interopRequireDefault(require("./svg/Download.svg"));
var _RatingStar = _interopRequireDefault(require("./svg/RatingStar.svg"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _AsyncButton = _interopRequireDefault(require("./AsyncButton"));
const _excluded = ["data", "onLeaveReviewClick", "orderId"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderBooking on Booking {
        id
        downloadVoucherUrl
        isCancellable
        isAlterable
        customerComment
        ...ReviewModal
    }
`;
const getDisclaimerText = orderItem => {
  switch (orderItem.status) {
    case 'ACCEPTED':
      {
        if (orderItem.downloadVoucherUrl) {
          return __jsx(_reactIntl.FormattedMessage, {
            id: "BBSynK",
            defaultMessage: [{
              "type": 0,
              "value": "Je voucher (betalingsbewijs en sleuteladres) is nu beschikbaar"
            }]
          });
        }
        return __jsx(_reactIntl.FormattedMessage, {
          id: "OB9QX7",
          defaultMessage: [{
            "type": 0,
            "value": "Je kunt je voucher downloaden zodra het restant is betaald"
          }]
        });
      }
    case 'PENDING':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "w7MQDY",
        defaultMessage: [{
          "type": 0,
          "value": "Deze reserveringsaanvraag is in behandeling"
        }]
      });
    case 'DECLINED':
      return '';
    case 'CANCELLED_PARTNER':
    case 'CANCELLED_EXTERNAL':
    case 'CANCELLED_GUEST':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "uUDkir",
        defaultMessage: [{
          "type": 0,
          "value": "Geannuleerde boeking"
        }]
      });
    default:
      return '';
  }
};
const orderItemIsBooking = item => item.__typename === 'Booking';
exports.orderItemIsBooking = orderItemIsBooking;
const OrderBooking = _ref => {
  let {
      data,
      onLeaveReviewClick,
      orderId
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    open,
    onClose,
    onOpen
  } = (0, _useSesame.default)();
  const {
    rentalUnit,
    arrivalDate,
    departureDate,
    review,
    status,
    downloadVoucherUrl,
    isCancellable,
    isAlterable,
    customerComment,
    id
  } = data;
  const readReviewModal = (0, _useSesame.default)();
  const router = (0, _router.useRouter)();
  const cancellableModal = (0, _useSesame.default)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const device = (0, _devicesize.useDeviceSize)();
  if (!rentalUnit) {
    return null;
  }
  const {
    name,
    accommodation: {
      hasPublicPage,
      name: accommodationName
    }
  } = rentalUnit;
  const bookingIsDone = (0, _isBefore.default)(new Date(departureDate), new Date());
  const itemName = (0, _createUnitTitle.default)(name, accommodationName, hasPublicPage);
  const {
    linkProps,
    renderAccommodationLink
  } = props;
  return __jsx(_OrderItem.default, props, __jsx(_grid.Flex, {
    alignItems: ['left', 'center'],
    flex: 1,
    flexDirection: ['column', null, null, 'row']
  }, __jsx(_Stack.default, null, rentalUnit?.jpegThumbnail && __jsx(_OrderItem.OrderItemThumbnail, (0, _extends2.default)({
    faded: _order.declinedStatuses.includes(status)
  }, (0, _createImgProps.default)(rentalUnit.jpegThumbnail, rentalUnit.webpThumbnail)))), __jsx(_Stack.default, {
    flex: "1",
    flexWrap: "wrap",
    my: [0, 4, null, null, null, null, 0],
    ml: [0, null, 4, null, null, null, 0],
    mx: 2
  }, __jsx(_Stack.default, {
    p: [4, null, null, 0],
    width: "100%",
    variant: "inline"
  }, !bookingIsDone && __jsx(_OrderItem.OrderItemDisclaimer, null, getDisclaimerText(data))), __jsx(_Stack.default, {
    variant: "inline",
    flexWrap: "wrap"
  }, __jsx(_OrderItem.OrderItemInfo, null, __jsx(_Stack.default, null, __jsx(_OrderItem.OrderItemName, null, linkProps?.href && renderAccommodationLink ? __jsx(_link.default, (0, _extends2.default)({
    passHref: true
  }, linkProps, {
    target: "_blank",
    rel: "noreferrer",
    legacyBehavior: true
  }), itemName) : itemName)), __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "vvl0X+",
    defaultMessage: [{
      "type": 0,
      "value": "Accommodatie, "
    }, {
      "type": 1,
      "value": "date"
    }, {
      "type": 0,
      "value": " — "
    }, {
      "type": 1,
      "value": "duration"
    }],
    values: {
      date: __jsx(_reactIntl.FormattedDate, {
        format: "DAYMONTHYEAR",
        value: new Date(arrivalDate)
      }),
      duration: __jsx(_reactIntl.FormattedMessage, {
        id: "bdCpFx",
        defaultMessage: [{
          "type": 2,
          "style": null,
          "value": "duration"
        }, {
          "type": 0,
          "value": " "
        }, {
          "type": 6,
          "pluralType": "cardinal",
          "value": "duration",
          "offset": 0,
          "options": {
            "one": {
              "value": [{
                "type": 0,
                "value": "nacht"
              }]
            },
            "other": {
              "value": [{
                "type": 0,
                "value": "nachten"
              }]
            }
          }
        }],
        values: {
          duration: (0, _trip.getTripDuration)(arrivalDate, departureDate)
        }
      })
    }
  })), __jsx(_grid.Flex, {
    flexDirection: "row"
  }, __jsx(_Body.default, {
    variant: "small"
  }, customerComment && __jsx(_TextButton.default, {
    size: "tiny",
    onClick: onOpen
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "9v8KW3",
    defaultMessage: [{
      "type": 0,
      "value": "Lees opmerking"
    }]
  })))), __jsx(_Stack.default, {
    mt: 4,
    flex: device === 'mobile' || device === 'tablet' ? 1 : 'unset',
    variant: device === 'mobile' || device === 'tablet' ? 'default' : 'inline',
    spacing: 4,
    style: {
      zIndex: 1
    }
  }, !review && bookingIsDone && onLeaveReviewClick && __jsx(_AsyncButton.default, {
    onClick: onLeaveReviewClick
  }, __jsx(_RatingStar.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "9KKQon",
    defaultMessage: [{
      "type": 0,
      "value": "Beoordelen"
    }]
  }))), !bookingIsDone && __jsx(_react.default.Fragment, null, downloadVoucherUrl && __jsx(_AsyncButton.default, {
    onClick: () => (0, _downloadTORFile.default)(downloadVoucherUrl, itemName)
  }, __jsx(_Download.default, null), __jsx("span", null, __jsx(_reactIntl.FormattedMessage, {
    id: "9BvYb9",
    defaultMessage: [{
      "type": 0,
      "value": "Voucher"
    }]
  }))), isAlterable && __jsx(_link.default, {
    href: {
      pathname: `/my/order/${orderId}/booking/${id}/alter`,
      query: {
        orderId
      }
    },
    passHref: true,
    legacyBehavior: true
  }, __jsx(_Button.default, {
    as: "div",
    variant: "outline"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "wB1MXv",
    defaultMessage: [{
      "type": 0,
      "value": "Wijzigen"
    }]
  }))), !isCancellable && __jsx(_react.default.Fragment, null, __jsx(_Button.default, {
    onClick: cancellableModal.onOpen,
    variant: "outline"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "lM6OnM",
    defaultMessage: [{
      "type": 0,
      "value": "Annuleren"
    }]
  })), __jsx(_Modal.default, {
    onClose: cancellableModal.onClose,
    open: cancellableModal.open,
    variant: "small"
  }, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "JzOZ7M",
    defaultMessage: [{
      "type": 0,
      "value": "Boeking annuleren"
    }]
  })), __jsx(_grid.Box, {
    my: 5
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "fbMph9",
    defaultMessage: [{
      "type": 0,
      "value": "Neem contact met ons op om deze boeking te annuleren."
    }]
  }), __jsx("br", null), __jsx(_Anchor.default, {
    as: "a",
    target: "_blank",
    rel: "noreferrer noopener",
    href: brandConfig.contact.page?.[router.locale]
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "0p12v7",
    defaultMessage: [{
      "type": 0,
      "value": "Neem contact op"
    }]
  }))))), isCancellable && __jsx(_link.default, {
    href: `/my/order/${orderId}/booking/${id}/cancel`,
    passHref: true,
    legacyBehavior: true
  }, __jsx(_Button.default, {
    as: "div",
    variant: "outline"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "lM6OnM",
    defaultMessage: [{
      "type": 0,
      "value": "Annuleren"
    }]
  })))), bookingIsDone && review && __jsx(_react.default.Fragment, null, __jsx(_OrderItem.OrderItemTextButton, {
    onClick: readReviewModal.onOpen,
    as: _TextButton.default,
    size: "tiny"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "qKmDlB",
    defaultMessage: [{
      "type": 0,
      "value": "Beoordeling lezen"
    }]
  })), __jsx(_ReviewModal.default, {
    data: data,
    open: readReviewModal.open,
    onClose: readReviewModal.onClose
  }))))))), __jsx(_Modal.default, {
    variant: "regular",
    open: open,
    onClose: onClose
  }, __jsx(_ModalHeader.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "CY8rCh",
    defaultMessage: [{
      "type": 0,
      "value": "Opmerking"
    }]
  })), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4
  }, __jsx(_Body.default, null, customerComment))));
};
var _default = exports.default = OrderBooking;