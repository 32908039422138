"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUpcoming = exports.isReviewable = exports.isRecent = exports.isDone = exports.isDeclined = exports.getOrderTitle = exports.declinedStatuses = void 0;
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _graphql = require("../generated/graphql");
var _parseDateTime = _interopRequireDefault(require("./parseDateTime"));
var __jsx = _react.default.createElement;
const declinedStatuses = exports.declinedStatuses = [_graphql.BookingStatus.Declined, _graphql.BookingStatus.CancelledGuest, _graphql.BookingStatus.CancelledPartner, _graphql.BookingStatus.CancelledExternal, _graphql.BookingStatus.Waived];
const isUpcoming = item => {
  let dateToCompare = new Date();
  if (item.__typename) {
    switch (item.__typename) {
      case 'Booking':
        dateToCompare = item.arrivalDate;
        break;
      case 'Ticket':
        {
          if (!item.timeslot) {
            return false;
          }
          dateToCompare = (0, _parseDateTime.default)(item.timeslot.startDateTime);
          break;
        }
      // TODO: implement UpsellPurchase status
      case 'UpsellPurchase':
        return false;
      default:
        throw new _UnreachableCaseError.UnreachableCaseError(item.__typename);
    }
  }
  return (0, _isAfter.default)(new Date(dateToCompare), new Date());
};
exports.isUpcoming = isUpcoming;
const isDone = item => (0, _isBefore.default)(new Date(item.departureDate), new Date());
exports.isDone = isDone;
const isReviewable = item => !item.review;
exports.isReviewable = isReviewable;
const isRecent = item => (0, _differenceInDays.default)(new Date(), new Date(item.departureDate)) < 60;
exports.isRecent = isRecent;
const isDeclined = item => {
  if (!item.__typename) {
    return true;
  }
  switch (item.__typename) {
    case 'Booking':
      return declinedStatuses.includes(item.status);
    case 'Ticket':
      // TODO: can a ticket be declined? Most probably, but it isn't supported yet.
      return false;
    // TODO: implement UpsellPurchase status
    case 'UpsellPurchase':
      return false;
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(item.__typename);
  }
};
exports.isDeclined = isDeclined;
const getOrderTitle = (order, locale) => {
  const mainBooking = order.orderItems.find(item => item.__typename === 'Booking');
  if (!mainBooking) {
    return __jsx(_reactIntl.FormattedMessage, {
      id: "Pc26lV",
      defaultMessage: [{
        "type": 0,
        "value": "Reservering ("
      }, {
        "type": 1,
        "value": "orderId"
      }, {
        "type": 0,
        "value": ")"
      }],
      values: {
        orderId: order.id ? __jsx(_link.default, {
          passHref: true,
          href: `/my/order/${order.id}`,
          legacyBehavior: true
        }, order.number ?? 1) : ''
      }
    });
  }
  switch (mainBooking?.status) {
    case 'ACCEPTED':
    case 'NO_SHOW':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "Pc26lV",
        defaultMessage: [{
          "type": 0,
          "value": "Reservering ("
        }, {
          "type": 1,
          "value": "orderId"
        }, {
          "type": 0,
          "value": ")"
        }],
        values: {
          orderId: order.id ? __jsx(_link.default, {
            passHref: true,
            href: `/${locale}/my/order/${order.id}`,
            legacyBehavior: true
          }, order.number ?? 1) : ''
        }
      });
    case 'DECLINED':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "nL5H7D",
        defaultMessage: [{
          "type": 0,
          "value": "Afgewezen reserveringsaanvraag"
        }]
      });
    case 'PENDING':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "2raF63",
        defaultMessage: [{
          "type": 0,
          "value": "Reserveringsaanvraag"
        }]
      });
    case 'CANCELLED_PARTNER':
    case 'CANCELLED_EXTERNAL':
    case 'CANCELLED_GUEST':
    case 'WAIVED':
      return __jsx(_reactIntl.FormattedMessage, {
        id: "uUDkir",
        defaultMessage: [{
          "type": 0,
          "value": "Geannuleerde boeking"
        }]
      });
    default:
      return '';
  }
};
exports.getOrderTitle = getOrderTitle;